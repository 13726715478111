import axios from "axios";
import url from "../url";
import { getArray } from "../resources/countryCodeResource";

export default class countryCodeRepository {
    async index() {
        const response = await axios.get(url("indexCountryCode"));
        if (response.status === 200) {
            return response.data;
        }
    }
}
