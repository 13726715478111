<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <div v-if="hasPermission">
                <div>
                    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                        <!-- Form -->
                        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                            <b-row>
                                <b-col cols="12">
                                    <!-- media -->
                                    <b-media no-body>
                                        <b-media-body class="mt-75 ml-75">
                                            <b-row>
                                                <b-col cols="12" sm="4">
                                                    <!-- upload button -->

                                                    <image-cropper v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />
                                                    <!--/ upload button -->
                                                </b-col>
                                            </b-row>
                                            <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                                        </b-media-body>
                                    </b-media>
                                    <!--/ media -->
                                </b-col>
                            </b-row>
                            <b-row class="mt-3">
                                <!-- First Name -->
                                <b-col cols="12" sm="6" md="4">
                                    <validation-provider #default="validationContext" name="firstName" rules="required">
                                        <b-form-group label="Name" label-for="first-name">
                                            <b-form-input id="first-name" v-model="form.first_name" autofocus :state="getValidationState(validationContext)" trim />

                                            <b-form-invalid-feedback>
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>

                                <!-- User name -->
                                <b-col cols="12" sm="6" md="4">
                                    <validation-provider name="username" vid="username" rules="required">
                                        <b-form-group label="User Name" label-for="username">
                                            <b-form-input id="username" v-model="form.user_name" @blur="checkExistUserName" trim :state="validUsername == true ? true : null" />

                                            <span class="text-danger">{{ usernameErrors }}</span>
                                            <small class="text-success">{{ usernameMsg }}</small>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <!-- Email -->
                                <b-col cols="12" sm="6" md="4">
                                    <validation-provider #default="validationContext" name="Email" rules="email">
                                        <b-form-group label="Email" label-for="email">
                                            <b-form-input id="email" v-model="form.email" :state="getValidationState(validationContext)" trim />

                                            <b-form-invalid-feedback>
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <!-- gender -->
                                <b-col cols="12" sm="6" md="4">
                                    <validation-provider #default="validationContext" name="Gender" vid="gender">
                                        <b-form-group label="Gender" label-for="register-gender" :state="getValidationState(validationContext)">
                                            <v-select v-model="form.gender" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="genders" label="name" :clearable="true" input-id="register-gender" :reduce="(option) => option" autocomplete="address-gender4" />
                                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <!-- country -->
                                <b-col cols="12" sm="6" md="4">
                                    <validation-provider #default="validationContext" name="Country">
                                        <b-form-group label="Country" label-for="country" :state="getValidationState(validationContext)">
                                            <v-select v-model="form.country_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="getCountries" label="name" :reduce="(val) => val.id" :clearable="true" input-id="country" autocomplete="address-level4" @option:selected="changeCountry" />
                                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <!-- city -->
                                <b-col cols="12" sm="6" md="4">
                                    <validation-provider #default="validationContext" name="City">
                                        <b-form-group label="City" label-for="city" :state="getValidationState(validationContext)">
                                            <v-select v-model="form.city_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="cities" label="name" :reduce="(val) => val.id" :clearable="true" input-id="city" autocomplete="address-level4" />
                                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col cols="12" sm="6" md="4">
                                    <validation-provider #default="validationContext" name="Level">
                                        <b-form-group label="Level" label-for="level" :state="getValidationState(validationContext)">
                                            <v-select v-model="form.level" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="levels" label="name" :clearable="true" input-id="level" autocomplete="address-level4" />
                                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col cols="12" sm="6" md="2">
                                    <validation-provider name="code">
                                        <b-form-group label="Country Code" label-for="code">
                                            <v-select v-model="form.country_code" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :reduce="(val) => val.code" :options="codes" label="name" :clearable="false" input-id="code">
                                                <template #option="{ name, code }">
                                                    <span> {{ code }} </span>
                                                    <span> {{ name }}</span>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <!-- Cell number -->
                                <b-col cols="12" sm="6" md="3">
                                    <!-- <validation-provider #default="validationContext" name="cell-number"> -->
                                    <b-form-group label="Cell number" label-for="cell-number">
                                        <b-form-input id="cell-number" v-model="form.cell_number" trim />

                                        <!-- <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback> -->
                                    </b-form-group>
                                    <!-- </validation-provider> -->
                                </b-col>
                                <b-col cols="12" sm="6" md="4">
                                    <validation-provider #default="validationContext" name="Verify Tick">
                                        <b-form-group label="Verify Tick" label-for="verify_tick" :state="getValidationState(validationContext)">
                                            <v-select v-model="form.badges" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="badges" :clearable="true" multiple />
                                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                        <b-img v-if="form.badges.includes('Celebrity')" class="mb-1 mb-sm-0" height="30" src="./images/celebrity.png" alt="Left image" />
                                        <b-img v-if="form.badges.includes('Corporate')" class="mb-1 mb-sm-0 ml-1" height="30" src="./images/corporate.png" alt="Left image" />
                                        <b-img v-if="form.badges.includes('Fashionista')" class="mb-1 mb-sm-0 ml-1" height="30" src="./images/fashionista.png" alt="Left image" />
                                        <b-img v-if="form.badges.includes('Verification')" class="mb-1 mb-sm-0 ml-1" height="30" src="./images/Badge.png" alt="Left image" />
                                        <b-img v-if="form.badges.includes('VIP')" class="mb-1 mb-sm-0 ml-1" height="30" src="./images/vip.png" alt="Left image" />
                                    </validation-provider>
                                </b-col>
                                <b-row class="justify-content-end mt-5"> </b-row>
                                <b-col v-if="showRole == 'addPage'" cols="12" sm="12" md="12" class="mt-1">
                                    <validation-provider #default="validationContext" name="is_admin">
                                        <b-form-group label-for="is_admin" :state="getValidationState(validationContext)">
                                            <b-form-radio-group v-model="form.is_admin" :options="userTypeOptions" name="is_admin" />
                                        </b-form-group>
                                    </validation-provider>
                                </b-col>
                                <b-col v-else-if="showRole == 'editPage'" cols="12" sm="12" md="12" class="mt-1"> * This User Role is : {{ form.role }} </b-col>
                                <!-- Form Actions -->
                                <b-col cols="12">
                                    <div class="d-flex mt-2">
                                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit">
                                            {{ userId ? "update" : "add" }}
                                        </b-button>
                                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-danger"> reset </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-form>
                    </validation-observer>
                </div>
            </div>
            <div v-else class="text-center">
                <h1>⛔ You are not Allowed to be here ⛔</h1>
            </div>
        </template>
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BFormCheckbox, BMedia, BFormSelect, BImg, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormRadioGroup } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { avatarText } from "@core/utils/filter";
import FileRepository from "@/abstraction/repository/fileRepository";
import UserRepository from "@/abstraction/repository/userRepository";
import CityRepository from "@/abstraction/repository/cityRepository";
import { makeid } from "@/utils/math";
import useUsersList from "./useUsersList";
import userStoreModule from "./userStoreModule";
import AuthRepository from "@/abstraction/repository/authRepository";
import CountryCodeRepository from "@/abstraction/repository/countryCodeRepository";
const countryCode = new CountryCodeRepository();
const authRepository = new AuthRepository();

const userRepository = new UserRepository();
const cityRepository = new CityRepository();
const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BForm,
        BFormSelect,
        BFormCheckbox,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        BFormRadioGroup,
        BFormGroup,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    data: () => ({
        required,
        email,
        genders: ["male", "female", "corporate"],
        usernameErrors: "",
        usernameMsg: "",
        validUsername: undefined,
    }),
    setup() {
        const blankform = {
            first_name: "",
            last_name: "",
            user_name: "",
            gender: "",
            email: "",
            level: "normal",
            is_artist: 0,
            badges: [],
            have_verify_tick: false,
            is_admin: false,
            file_batch_id: RANDOM_TOKEN,
        };
        const userTypeOptions = ref([
            { text: "Panel Admin & Application User", value: true },
            { text: "Only Application User", value: false },
        ]);
        const levels = ref(["premium", "promoted", "normal"]);
        const userId = ref(router.currentRoute.params.id);
        const cities = ref([]);
        const fileForm = ref({
            batch_id: RANDOM_TOKEN,
            collection_name: "profile",
            model_name: "user",
            crop: {},
        });
        const codes = ref([]);
        const refInputEl = ref(null);
        const previewEl = ref(null);
        const badges = ref(["Verification", "Celebrity", "Fashionista", "Corporate", "VIP"]);
        const planOptions = [
            { label: "Basic", value: "basic" },
            { label: "Company", value: "company" },
            { label: "Enterprise", value: "enterprise" },
            { label: "Team", value: "team" },
        ];
        const form = ref(JSON.parse(JSON.stringify(blankform)));
        const showRole = ref("");
        const USER_APP_STORE_MODULE_NAME = "app-user";

        const { refetchData } = useUsersList();
        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });
        onMounted(async () => {
            if (userId.value) {
                const resource = await userRepository.show(userId.value);
                form.value = resource;
                showRole.value = "editPage";
                if (form.value.have_verify_tick == 0) {
                    form.value.have_verify_tick = false;
                } else if (form.value.have_verify_tick == 1) {
                    form.value.have_verify_tick = true;
                }
                form.value.file_batch_id = RANDOM_TOKEN;
                if (form.value.country_id) {
                    const resoruce = await cityRepository.index(form.value.country_id);
                    cities.value = resoruce.data;
                }
            } else {
                showRole.value = "addPage";
            }
            const countryCodeResoruce = await countryCode.index();
            codes.value = countryCodeResoruce.data;
        });
        const resetform = () => {
            form.value = JSON.parse(JSON.stringify(blankform));
        };
        const onSubmit = async () => {
            console.log(form.value.badges);
            try {
                const fileRepository = new FileRepository();
                if (router.currentRoute.params.id) {
                    if (fileForm.value.file) {
                        await fileRepository.store(fileForm.value);
                    }
                    if (form.value.email == null || form.value.email.length == "") {
                        delete form.value.email;
                    }
                    // if (form.value.email.length) {
                    //     if (form.value.email.length == "") {
                    //         delete form.value.email;    
                    //     }
                    // }
                    if (form.value.country_code == null) {
                        delete form.value.country_code;
                    }
                    if (form.value.cell_number == null) {
                        delete form.value.cell_number;
                    }
                    await userRepository.update(form.value.id, form.value);
                    router.push({ name: "apps-users-list" });
                } else {
                    // if (form.value.email.length) {
                    //     if (form.value.email.length == "") {
                    //         delete form.value.email;    
                    //     }
                    // }
                    // if (form.value.email.length && form.value.email.length == "") {
                    //     delete form.value.email;
                    // }
                     if (form.value.email == null || form.value.email.length == "") {
                        delete form.value.email;
                    }
                    if (form.value.country_code == null) {
                        delete form.value.country_code;
                    }
                    if (form.value.cell_number == null) {
                        delete form.value.cell_number;
                    }
                    await fileRepository.store(fileForm.value);
                    await userRepository.store(form.value);
                    router.push({ name: "apps-users-list" });
                }
            } catch (e) {
                console.log(e);
                return e;
            }
        };
        const { refFormObserver, getValidationState, resetForm } = formValidation(resetform);
        // const hasPermission = ref(false);
        // const checkPermissions = () => {
        //     let logedInUserPermissions = JSON.parse(localStorage.getItem("userData")).permissions.map((item) => {
        //         return item.name;
        //     });
        //     console.log(logedInUserPermissions.includes("User.list"));
        //     if (logedInUserPermissions.includes("User.add")) {
        //         hasPermission.value = true;
        //     }
        // };

        const hasPermission = ref(false);
        const checkPermissions = () => {
            let logedInUserPermissions = JSON.parse(localStorage.getItem("userData")).permissions.map((item) => {
                return item.name;
            });
            if (logedInUserPermissions.includes("User.add")) {
                hasPermission.value = true;
            }
        };
        checkPermissions();
        return {
            form,
            refetchData,
            onSubmit,
            userId,
            cities,
            levels,
            refFormObserver,
            getValidationState,
            resetForm,
            avatarText,
            planOptions,
            userTypeOptions,
            refInputEl,
            previewEl,
            fileForm,
            showRole,
            hasPermission,
            badges,
            codes,
        };
    },
    computed: {
        ...mapGetters("country", ["getCountries"]),
    },
    methods: {
        ...mapActions("country", ["loadCountries"]),
        async checkExistUserName() {
            const form = {
                user_name: this.form.user_name,
                except_id: this.form.id,
            };
            this.usernameErrors = "";
            this.usernameMsg = "";
            if (this.form.user_name.length == 0) {
                this.usernameErrors = "";
                this.usernameMsg = "";
                this.validUsername = false;
            }
            if (this.form.user_name.length < 2) {
                this.usernameMsg = "";
                this.usernameErrors = "username must be at least 2 characters";
                this.validUsername = false;
                return;
            }
            authRepository
                .checkExistUserName(form)
                .then((res) => {
                    if (res.status == false) {
                        this.validUsername = true;
                        this.usernameMsg = "username is valid :)";
                    } else if (res.status == true) {
                        this.validUsername = false;
                        this.usernameErrors = "Unavailable Username Because it's Duplicated :(";
                    }
                })
                .catch((err) => {
                    this.usernameErrors = err.response.data.errors.user_name[0];
                    this.validUsername = false;
                });
            // let response = await authRepository.checkExistUserName(form);
            // console.log(response);
            // if (response.status) {
            //     console.log(this.$refs.refFormObserver.errors.username);
            //     this.$refs.refFormObserver.errors.username.push("this username is already taken");
            //     this.$refs.refFormObserver.setErrors("UserName", ["error"]);
            // }
        },
        async changeCountry() {
            if (this.form.country_id) {
                const resoruce = await cityRepository.index(this.form.country_id);
                this.cities = resoruce.data;
            }
        },
    },
    created() {
        this.loadCountries();
    },
    beforeRouteLeave(to, from, next) {
        this.resetForm();
        next(true);
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.valid {
    border: 1px solid rgb(0, 170, 25);
}
</style>
